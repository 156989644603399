import React, { memo } from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    div: {
        width: `100%`,

        [theme.breakpoints.up("sm")]: {
            width: `40%`
        },
    },
    h4: {
        textAlign: `center`,
        margin: `${theme.spacing(1)} 0`,
        fontSize: theme.typeScale[`36px`],
        fontWeight: 600,

        [theme.breakpoints.up("sm")]: {
            fontSize: theme.typeScale['30px'],
        },
    }
}));

function SharpImage(props) {

    const { fluid, title } = props;
    const classes = useStyles();

    return (
        <div className={classes.div}>
            <Typography variant={`h4`} classes={{ h4: classes.h4 }}>{title}</Typography>
            <Img style={{ overflow: 'visible' }} fluid={fluid}/>
        </div>
    );

}

SharpImage.propTypes = {
    fluid: PropTypes.object.isRequired,
};

export default memo(SharpImage);
