import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Icon from '../icon';


const useStyles = makeStyles(theme => ({

    div: {
        backgroundColor: theme.palette.neutral[1100],
        display: `inline-flex`,
        justifyContent: `flex-end`,
        width: `100%`,
        margin: `theme.spacing(.5) 0`,
        marginTop: `-6px`,
        padding: theme.spacing(1),

        "& > div:last-child": {
            marginRight: 0
        },

        [theme.breakpoints.up(`sm`)]: {
            borderRadius: 0,
        },

        [theme.breakpoints.up(`md`)]: {
            "& > div:last-child": {
                marginRight: theme.spacing(1),
            },
        },
    },

    link: {
        transition: theme.transition,
        textDecoration: `none`,
        "&:hover": {
            color: theme.palette.primary['500'],
            textDecoration: `underline`,
        },
    },

    item: {
        color: theme.palette.neutral[200],
        display: `inline-flex`,
        alignItems: `center`,
        marginRight: theme.spacing(1),

        [theme.breakpoints.up(`sm`)]: {
            marginRight: theme.spacing(1.5),
        },
    },

    span: {
        marginLeft: theme.spacing(.25),
        fontSize: theme.typeScale[`14px`],
        [theme.breakpoints.up(`sm`)]: {
            fontSize: theme.typeScale[`16px`],
        },
    },

}));


function PostFooter(props) {

    //todo: make this component categories display 'array-friendly'

    // do some destructuring
    const { categories, date } = props;
    const classes = useStyles();

    return (
        <div className={classes.div}>
            <Link className={`${classes.link} ${classes.item}`} to={`/${categories}`}>
                <Icon
                    path={"M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 0 0-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z"}/>
                <span className={classes.span}>{categories[0]}</span>
            </Link>
            <div className={classes.item}>
                <Icon
                    path={"M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"}/>
                <span className={classes.span}>{date}</span>
            </div>
        </div>
    );
}

PostFooter.propTypes = {
    categories: PropTypes.array.isRequired,
    date: PropTypes.string.isRequired
};

export default memo(PostFooter);