import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Img from 'gatsby-image';

import PostTitle from './post-title';

const styles = theme => ({});

class PostHeader extends PureComponent {

    render() {

        // debugging
        // console.log('props in PostHeader', this.props);

        const { headerImage, hideTitleText, title, slug, classes } = this.props;
        const { header, image } = classes;

        return (
            <div className={header}>
                {!hideTitleText && <PostTitle slug={slug}>{title}</PostTitle>}
                {headerImage && <Img className={image} fluid={headerImage.childImageSharp.fluid}/>}
            </div>
        );
    }
}

PostHeader.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    headerImage: PropTypes.object
};

export default withStyles(styles)(PostHeader);