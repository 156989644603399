import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import { withMDXScope } from 'gatsby-mdx/context';
import { MDXProvider } from "@mdx-js/tag";

import SharpImage from "../sharp-image";


const useStyles = makeStyles(theme => ({
    div: {
        wordWrap: `break-word`,
        hyphens: `auto`,

        '& a': {
            color: theme.palette.primary['500'],
            textDecoration: `none`,
        },

        '& h2': {
            marginTop: theme.spacing(2.5),
        },

        '& p': {
            lineHeight: 1.6,
            fontSize: theme.typeScale[`16px`]
        },

        // standalone iframes
        '& iframe:last-child': {
            marginBottom: 0,
        },

    },
}));

function PostContent(props) {

    // do some destructuring
    const { body, images, scope } = props;
    const classes = useStyles();

    const imgs = images
        ? images.reduce((acc, image, idx) => {
            acc[`Image${idx + 1}`] = ({ title }) => (
                <SharpImage title={title} fluid={image.childImageSharp.fluid}/>
            );
            return acc;
        }, {})
        : {};

    return (
        <MDXProvider components={{ ...imgs }}>
            <div className={classes.div}>
                <MDXRenderer scope={{ ...scope, ...imgs }}>{body}</MDXRenderer>
            </div>
        </MDXProvider>
    );

}

export default withMDXScope(memo(PostContent));