import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: `none`
    },
    title: {
        marginBottom: theme.spacing(1.5),
        fontWeight: 600,
    },
}));

PostTitle.propTypes = {
    children: PropTypes.node.isRequired,
    slug: PropTypes.string.isRequired,
};

function PostTitle(props) {
    const { slug, children } = props;
    const classes = useStyles();
    return (
        <Link className={classes.link} to={slug}>
            <Typography variant={'h1'} component={'h1'} className={classes.title}>{children}</Typography>
        </Link>
    );

}

export default memo(PostTitle)

