import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(2),
        maxWidth: 800,
        width: `100%`,
        [theme.breakpoints.up(`sm`)]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.up(`md`)]: {
            padding: theme.spacing(4),
        },
    },
}));


function Content(props) {
    const classes = useStyles();
    return (
        <div className={classes.content}>
            {props.children}
        </div>
    );
};

Content.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(Content);
