import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    h1: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary[500],
        fontSize: theme.typeScale[`36px`],
        wordWrap: `break-word`,
        hyphens: `auto`,
        width: `100%`,
        margin: 0,
        zIndex: 3,
        padding: theme.spacing(1, 2),
        [theme.breakpoints.up(`sm`)]: {
            padding: theme.spacing(2, 3),
        },
        [theme.breakpoints.up(`md`)]: {
            padding: theme.spacing(2, 4),
        },

    }
}));

function PageTitle(props) {

    const classes = useStyles();

    return (
        <h1 className={classes.h1}>{props.children}</h1>
    );

}

export default memo(PageTitle);