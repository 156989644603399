import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PageTitle from './page-title'
import { Hidden } from '@material-ui/core';

export default () => {
    const { site } = useStaticQuery(graphql`
        query TitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    const { siteMetadata } = site;
    return (
        <Hidden mdUp>
            <PageTitle><Link
                style={{ textDecoration: `none`, color: `white` }}
                to={`/`}>{siteMetadata.title}</Link>
            </PageTitle>
        </Hidden>
    );
};





