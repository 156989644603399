import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';
import Post from './post/post.js';
import SEO from './seo';
import Content from './content';
import MobileBranding from './mobile-branding';


export default function PostSingle({ data }) {

    const { mdx } = data;
    const { fields, frontmatter, code } = mdx;
    const { title, categories } = frontmatter;

    return (
        <Fragment>
            <MobileBranding/>
            <Content>
                <SEO title={title} keywords={categories}/>
                <Post fields={fields} frontmatter={frontmatter} body={code.body}/>
            </Content>
        </Fragment>
    );
}

export const query = graphql`
    query pageQuery($id: String!) {
        mdx(id: { eq: $id }) {
            ...post
        }
    }
`;