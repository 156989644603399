import React, { memo } from 'react';

import PostHeader from './post-header';
import PostContent from './post-content';
import PostFooter from './post-footer';


function Post(props) {

    const { fields, frontmatter, body } = props;
    const { date, categories, images } = frontmatter;

    return (
        <article>
            <PostHeader slug={fields.slug} {...frontmatter}/>
            <PostContent images={images} body={body}/>
            <PostFooter date={date} categories={categories}/>
        </article>);

}

export default memo(Post);